var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import MoveTo from 'moveto';
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { CLAIM_TYPE_TRANSITION_DURATION } from '@declaration/animations/claimType';
import { QUESTION_HISTORY_SECTION_TRANSITION, QUESTION_HISTORY_TRANSITION, QUESTION_TRANSITION_BACKWARD, QUESTION_TRANSITION_FORWARD, QUESTIONS_SPLASH_LEAVE_TRANSITION, QUESTIONS_STACK_TRANSITION, } from '@declaration/animations/questionsPageTransitions';
import { ANIMATION_DURATIONS } from '@declaration/animations/utils';
import ProgressBar from '@declaration/components/ProgressBar.vue';
import SplashScreen from '@declaration/components/qualification/SplashScreen.vue';
import LargeSplashScreen from '@declaration/components/qualification/LargeSplashScreen.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import ClaimTypeQuestion from '@declaration/components/qualification/ClaimTypeQuestion.vue';
import { AppointmentFromEstimationExitTypes } from '@declaration/constants/brmsConstants';
import { DECLARATION_SECTION_TRAD_KEYS, } from '@declaration/constants/declarationSectionConstants';
import { goBackOneStep, quitClaimDeclarationCloseCallback, } from '@declaration/helpers/layoutMetaCallback';
import { CLAIM_TYPE_LAYOUT_COLORS } from '@declaration/layout';
import { QuestionComponents } from '@declaration/questionComponents';
import { DeclarationSection } from '@declaration/sections';
import { Certification, } from '@declaration/store/pages';
import { CLAIM_TYPE_TRIGRAM_TRAD_KEYS } from '@shared/claim/labels';
import { Step } from '@shared/claim/steps';
import { hasFeature } from '@shared/featureFlags/featureFlags';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { INSURER } from '@shared/insurer';
import { COLORS, KEEP_LAYOUT_COLOR } from '@shared/layout/layout';
import { ClaimTypeTrigram, RobberyEventType, } from '@shared/types/api/claim';
import { formatOptionalDate } from '@shared/utils/date';
import { Question } from '@declaration/questions';
const layout = namespace('layout');
const claim = namespace('claim');
const qualification = namespace('qualification');
const pages = namespace('pages');
const personalProperty = namespace('personalProperty');
const roomDamage = namespace('roomDamage');
const electricalDamage = namespace('electricalDamage');
const externalDamage = namespace('externalDamage');
const breakInDamage = namespace('breakInDamage');
const glassDamage = namespace('glassDamage');
const equipment = namespace('equipment');
let Questions = class Questions extends Vue {
    constructor() {
        super(...arguments);
        this.shouldDisplaySplashScreen = true;
        this.isSplashScreenLoading = false;
        this.loaded = false;
        this.isCertified = false;
        this.isDamagesReportCertified = false;
        this.displayProgressBar = false;
        this.desktopComponentAnimationDirection = 'forward';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.timeouts = [];
        this.splashLeave = QUESTIONS_SPLASH_LEAVE_TRANSITION.leave;
        this.historyTransition = QUESTION_HISTORY_TRANSITION;
        this.historySectionTransition = QUESTION_HISTORY_SECTION_TRANSITION;
        this.questionsStackBeforeEnter = QUESTIONS_STACK_TRANSITION.beforeEnter;
        this.questionsStackEnter = QUESTIONS_STACK_TRANSITION.enter;
        this.questionsStackBeforeLeave = QUESTIONS_STACK_TRANSITION.beforeLeave;
        this.questionsStackLeave = QUESTIONS_STACK_TRANSITION.leave;
        this.isSavingCertification = false;
    }
    get isCertificationMode() {
        return this.shouldCertify && this.activeQuestion === null;
    }
    onGeneraliOnly() {
        return INSURER === 'generali' || INSURER === 'lamedicale';
    }
    onFirstSummary() {
        return this.claim.nextStep === Step.QualificationCertification;
    }
    get shouldDisplayDateAndTypeCards() {
        return (insurerHasFeature(InsurerFeatureFlag.DisplayDateAndTypeWhenComingFromPriming) &&
            this.claim.nextStep === Step.Sumup &&
            this.claim.origin === 'priming' &&
            !this.claim.stepStack.includes(Step.ClaimDate));
    }
    get claimTypeStep() {
        return Step.ClaimType;
    }
    get componentStackSections() {
        var _a, _b;
        const componentStackSections = {};
        let sectionIndex = 0;
        for (const component of this.declarationComponentStack) {
            if (component.section in componentStackSections) {
                (_a = componentStackSections[component.section]) === null || _a === void 0 ? void 0 : _a.componentStack.push(component);
            }
            else {
                componentStackSections[component.section] = {
                    section: component.section,
                    componentStack: [component],
                    title: this.computeSectionTitle(component.section, sectionIndex++),
                    isLocked: component.section === DeclarationSection.QualificationSection &&
                        !!this.claim.qualificationCertifiedAt,
                };
            }
        }
        if (this.shouldDisplayDateAndTypeCards) {
            (_b = componentStackSections[DeclarationSection.QualificationSection]) === null || _b === void 0 ? void 0 : _b.componentStack.unshift({
                question: Question.ClaimDateQuestion,
                section: DeclarationSection.QualificationSection,
                step: Step.ClaimDate,
            });
        }
        return Object.values(componentStackSections);
    }
    get componentStackSectionsLength() {
        return this.componentStackSections.length;
    }
    get questionTransition() {
        return this.desktopComponentAnimationDirection === 'forward'
            ? QUESTION_TRANSITION_FORWARD
            : QUESTION_TRANSITION_BACKWARD;
    }
    get shouldDisplayProgressBar() {
        return this.displayProgressBar && !this.isCertificationMode && !this.shouldDisplaySplashScreen;
    }
    get shouldDisplayDesktopProgressBar() {
        return !this.isMobile && this.shouldDisplayProgressBar;
    }
    get shouldDisplayMobileProgressBar() {
        return this.isMobile && this.shouldDisplayProgressBar;
    }
    get hasSplashScreen() {
        return hasFeature(InsurerFeatureFlag.SplashScreen, this.typeTrigram);
    }
    get hasLargeSplashScreen() {
        return hasFeature(InsurerFeatureFlag.IsLargeSplashScreen, this.typeTrigram);
    }
    get sectionTitleTranslationContext() {
        return {
            damageDate: formatOptionalDate(this.claim.damageDate),
        };
    }
    getActiveQuestionComponent() {
        return this.$refs.activeQuestion;
    }
    created() {
        if (this.declarationComponentStack.length > 1) {
            this.shouldDisplaySplashScreen = false;
        }
        // Scroll to last question on switch layout desktop -> mobile
        this.$watch('isMobile', (isMobile) => {
            if (!isMobile) {
                return;
            }
            this.$nextTick(() => {
                this.scrollToLastQuestion(false);
            });
        });
        // Scroll to top on certification
        this.$watch('shouldCertify', (shouldCertify) => {
            this.$nextTick(() => {
                if (shouldCertify) {
                    this.scrollToTop();
                    // Scroll to top after animation has completed to be sure that
                    // transition is over and container size is final
                    setTimeout(this.scrollAsideToTop, ANIMATION_DURATIONS.Detailed);
                    return;
                }
                this.scrollAsideToBottom();
            });
        });
        // This is done on mounted after animation, only trigger on subsequent section changes
        this.$watch('activeSection', () => {
            this.setSectionAsTitle();
        });
    }
    async mounted() {
        var _a, _b;
        // Set color of header after transition animation
        // if transition has been triggered
        if ((_b = (_a = this.$el) === null || _a === void 0 ? void 0 : _a.dataset) === null || _b === void 0 ? void 0 : _b.questionsTransition) {
            // Change text color first
            this.timeouts.push(setTimeout(() => {
                this.setColor({
                    ...this.color,
                    headerBackgroundColor: CLAIM_TYPE_LAYOUT_COLORS[this.typeTrigram].headerBackgroundColor,
                    text: CLAIM_TYPE_LAYOUT_COLORS[this.typeTrigram].text,
                });
            }, CLAIM_TYPE_TRANSITION_DURATION * 500));
            this.timeouts.push(setTimeout(() => {
                this.setColor(CLAIM_TYPE_LAYOUT_COLORS[this.typeTrigram]);
                this.setSectionAsTitle();
                this.displayProgressBar = true;
            }, CLAIM_TYPE_TRANSITION_DURATION * 1000));
        }
        else {
            this.setColor(CLAIM_TYPE_LAYOUT_COLORS[this.typeTrigram]);
            this.setSectionAsTitle();
            this.displayProgressBar = true;
        }
        // If no splash screen, start claim
        if (!this.hasSplashScreen && !this.hasLargeSplashScreen) {
            await this.startClaim();
            this.shouldDisplaySplashScreen = false;
        }
        if (this.isMobile) {
            this.scrollToLastQuestion(false);
        }
        else {
            this.scrollAsideToBottom(false);
        }
    }
    async closeSplashScreen() {
        if (this.isUnavailable) {
            goBackOneStep(this);
            return;
        }
        await this.startClaim();
        this.shouldDisplaySplashScreen = false;
    }
    computeSectionTitle(section, index) {
        return `${index + 1}/ ${this.$tc(DECLARATION_SECTION_TRAD_KEYS[section], 1, this.sectionTitleTranslationContext)}`;
    }
    onBeforeQuestionLeave(el) {
        var _a, _b;
        (_b = (_a = this.questionTransition).beforeLeave) === null || _b === void 0 ? void 0 : _b.call(_a, el);
        this.$nextTick(() => { var _a, _b; return (_b = (_a = this.getActiveQuestionComponent()) === null || _a === void 0 ? void 0 : _a.beforeLeave) === null || _b === void 0 ? void 0 : _b.call(_a); });
    }
    onQuestionLeave(el, done) {
        var _a, _b;
        (_b = (_a = this.questionTransition).leave) === null || _b === void 0 ? void 0 : _b.call(_a, el, done);
        this.$nextTick(() => { var _a, _b; return (_b = (_a = this.getActiveQuestionComponent()) === null || _a === void 0 ? void 0 : _a.leave) === null || _b === void 0 ? void 0 : _b.call(_a); });
    }
    onAfterQuestionLeave(el) {
        var _a, _b;
        (_b = (_a = this.questionTransition).afterLeave) === null || _b === void 0 ? void 0 : _b.call(_a, el);
        this.$nextTick(() => { var _a, _b; return (_b = (_a = this.getActiveQuestionComponent()) === null || _a === void 0 ? void 0 : _a.afterLeave) === null || _b === void 0 ? void 0 : _b.call(_a); });
    }
    onBeforeQuestionEnter(el) {
        var _a, _b;
        (_b = (_a = this.questionTransition).beforeEnter) === null || _b === void 0 ? void 0 : _b.call(_a, el);
        this.$nextTick(() => { var _a, _b; return (_b = (_a = this.getActiveQuestionComponent()) === null || _a === void 0 ? void 0 : _a.beforeEnter) === null || _b === void 0 ? void 0 : _b.call(_a); });
    }
    onQuestionEnter(el, done) {
        var _a, _b;
        (_b = (_a = this.questionTransition).enter) === null || _b === void 0 ? void 0 : _b.call(_a, el, done);
        this.$nextTick(() => { var _a, _b; return (_b = (_a = this.getActiveQuestionComponent()) === null || _a === void 0 ? void 0 : _a.enter) === null || _b === void 0 ? void 0 : _b.call(_a); });
    }
    onAfterQuestionEnter(el) {
        var _a, _b;
        (_b = (_a = this.questionTransition).afterEnter) === null || _b === void 0 ? void 0 : _b.call(_a, el);
        this.desktopComponentAnimationDirection = 'forward';
        this.$nextTick(() => { var _a, _b; return (_b = (_a = this.getActiveQuestionComponent()) === null || _a === void 0 ? void 0 : _a.afterEnter) === null || _b === void 0 ? void 0 : _b.call(_a); });
    }
    async startClaim() {
        var _a;
        // If status is "created", send a new startedAt date
        // Backend will then update the startedAt value and dispatch CLAIM_STARTED event
        if (!((_a = this.rawClaim) === null || _a === void 0 ? void 0 : _a.startedAt)) {
            this.isSplashScreenLoading = true;
            await this.saveClaimWithPayload({
                startedNow: true,
                keepStep: true,
            }).finally(() => {
                this.isSplashScreenLoading = false;
            });
        }
    }
    destroyed() {
        for (const timeout of this.timeouts) {
            clearTimeout(timeout);
        }
    }
    scrollToLastQuestion(smooth = true) {
        const questions = this.$refs.questionsStack;
        if (!(questions === null || questions === void 0 ? void 0 : questions.$el)) {
            return;
        }
        const last = questions.$el.lastChild;
        if (!last) {
            return;
        }
        const moveTo = new MoveTo({
            tolerance: 0,
            duration: smooth ? ANIMATION_DURATIONS.Detailed * 1000 : 0,
            container: window,
        });
        moveTo.move(last);
    }
    scrollToTop() {
        const moveTo = new MoveTo({
            tolerance: 0,
            duration: ANIMATION_DURATIONS.Detailed * 1000,
            container: window,
        });
        moveTo.move(document.body);
    }
    scrollAsideToTop(smooth = true) {
        const aside = this.$refs.questionsStackAside;
        if (!aside) {
            return;
        }
        if (!smooth) {
            aside.scrollTop = 0;
            return;
        }
        const moveTo = new MoveTo({
            tolerance: 0,
            duration: ANIMATION_DURATIONS.Detailed * 1000,
            container: aside,
        });
        moveTo.move(-aside.scrollTop);
    }
    scrollAsideToBottom(smooth = true) {
        const aside = this.$refs.questionsStackAside;
        if (!aside) {
            return;
        }
        if (!smooth) {
            aside.scrollTop = aside.scrollHeight - aside.clientHeight;
            return;
        }
        const moveTo = new MoveTo({
            tolerance: 0,
            duration: ANIMATION_DURATIONS.Detailed * 1000,
            container: aside,
        });
        moveTo.move(aside.scrollHeight - aside.clientHeight - aside.scrollTop);
    }
    onHistoryComponentClick({ step }) {
        if (this.isUpdatingClaim) {
            return;
        }
        const activeComponentIndex = this.declarationComponentStack.findIndex((component) => component.step === this.activeStep);
        const componentClickedIndex = this.declarationComponentStack.findIndex((component) => component.step === step);
        if (activeComponentIndex === -1) {
            // certification case
            this.desktopComponentAnimationDirection = 'backward';
        }
        else if (componentClickedIndex >= activeComponentIndex) {
            this.desktopComponentAnimationDirection = 'forward';
        }
        else {
            this.desktopComponentAnimationDirection = 'backward';
        }
        this.selectActiveStep(step);
    }
    onSmallQuestionInStackClick(component) {
        if (this.isUpdatingClaim) {
            return;
        }
        this.selectActiveStep(component.step);
    }
    get certificationCheckboxClass() {
        if (this.isMobile) {
            return ['is-dark', 'is-bold'];
        }
        if (this.color.text === COLORS.white) {
            return ['is-white', 'is-bold'];
        }
        return ['is-dark', 'is-bold'];
    }
    onCertifyCheckboxInput(certified) {
        this.isCertified = certified;
    }
    onCertifyDamagesReportCheckboxInput(certified) {
        this.isDamagesReportCertified = certified;
    }
    get isLostKeysDeclaration() {
        return (this.typeTrigram === ClaimTypeTrigram.Robbery &&
            this.robberyDamageQualification.eventType === RobberyEventType.LostKeys);
    }
    get isValidationButtonDisabled() {
        if (this.shouldCertify === Certification.Qualification) {
            return !this.canCertify || !this.isCertified;
        }
        else if (this.shouldCertify === Certification.Sumup) {
            return (!this.canCertify ||
                !this.isCertified ||
                (insurerHasFeature(InsurerFeatureFlag.DisplayRobbedDamagesReportCertificationCheckbox) &&
                    this.typeTrigram === ClaimTypeTrigram.Robbery &&
                    this.robberyDamageQualification.eventType !== RobberyEventType.LostKeys
                    ? !this.isDamagesReportCertified
                    : false));
        }
        return false;
    }
    get canCertifyDamagesReport() {
        return (this.shouldCertify === Certification.Sumup &&
            insurerHasFeature(InsurerFeatureFlag.DisplayRobbedDamagesReportCertificationCheckbox) &&
            this.typeTrigram === ClaimTypeTrigram.Robbery &&
            this.robberyDamageQualification.eventType !== RobberyEventType.LostKeys);
    }
    get canCertify() {
        if (this.shouldCertify === Certification.Qualification) {
            return true;
        }
        if (this.claim.exit !== null && AppointmentFromEstimationExitTypes.includes(this.claim.exit)) {
            return true;
        }
        if (this.typeTrigram === ClaimTypeTrigram.Robbery ||
            this.typeTrigram === ClaimTypeTrigram.StormSnowHail ||
            this.typeTrigram === ClaimTypeTrigram.Electric) {
            return true;
        }
        return (this.roomDamages.length > 0 ||
            this.personalProperties.length > 0 ||
            this.externalDamages.length > 0 ||
            this.electricalDamages.length > 0 ||
            this.breakInDamages.length > 0 ||
            this.glassDamages.length > 0 ||
            this.equipments.length > 0 ||
            (this.isLostKeysDeclaration && this.robberyDamageQualification.lostKeysLocks != null));
    }
    get certificationWording() {
        if (this.shouldCertify === Certification.Qualification) {
            return {
                title: this.$tc('claim.qualification.certification.title'),
                info: this.$tc('claim.qualification.certification.info'),
                certifyCheckboxLabel: this.$tc('claim.qualification.certification.label'),
                submitLabel: this.onGeneraliOnly()
                    ? this.$tc('claim.qualification.certification.confirm.generali')
                    : this.$tc('claim.qualification.certification.confirm'),
            };
        }
        if (this.canCertify) {
            return {
                title: this.$tc('claim.sumup.title'),
                info: this.$tc('claim.sumup.info'),
                certifyCheckboxLabel: this.$tc('claim.sumup.certification'),
                submitLabel: this.$tc('claim.sumup.button.confirm'),
                reportedDamagesCheckboxLabel: this.$tc('claim.qualification.certification.reported_damages.label'),
            };
        }
        return {
            title: this.$tc('claim.sumup.no_damages.title'),
            info: this.$tc('claim.sumup.no_damages.info'),
            certifyCheckboxLabel: this.$tc('claim.sumup.certification'),
            submitLabel: this.$tc('claim.sumup.button.confirm'),
            reportedDamagesCheckboxLabel: this.$tc('claim.qualification.certification.reported_damages.label'),
        };
    }
    async confirmCertification() {
        const certification = this.shouldCertify;
        if (!certification) {
            return;
        }
        this.isSavingCertification = true;
        if (certification === Certification.Qualification) {
            await this.updateAndSaveClaim({
                step: Step.QualificationCertification,
                claimPayload: { qualificationCertifiedAt: new Date().toISOString() },
            });
            this.isSavingCertification = false;
            this.isCertified = false;
            this.isDamagesReportCertified = false;
            return;
        }
        await this.updateAndSaveClaim({
            step: Step.Sumup,
            claimPayload: { damagesValidatedAt: new Date().toISOString() },
        });
    }
    setSectionAsTitle() {
        if (this.activeSection) {
            this.setTitle(this.$tc(DECLARATION_SECTION_TRAD_KEYS[this.activeSection], 1, this.sectionTitleTranslationContext));
            return;
        }
        this.setTitle(this.$tc(CLAIM_TYPE_TRIGRAM_TRAD_KEYS[this.typeTrigram]));
    }
};
Questions.color = KEEP_LAYOUT_COLOR;
__decorate([
    qualification.State('typeTrigram')
], Questions.prototype, "typeTrigram", void 0);
__decorate([
    layout.State('color')
], Questions.prototype, "color", void 0);
__decorate([
    layout.State('isMobile')
], Questions.prototype, "isMobile", void 0);
__decorate([
    layout.Getter('isDesktop')
], Questions.prototype, "isDesktop", void 0);
__decorate([
    layout.Action('setColor')
], Questions.prototype, "setColor", void 0);
__decorate([
    layout.Action('setTitle')
], Questions.prototype, "setTitle", void 0);
__decorate([
    claim.State('claim')
], Questions.prototype, "claim", void 0);
__decorate([
    claim.State('rawClaim')
], Questions.prototype, "rawClaim", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], Questions.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], Questions.prototype, "updateAndSaveClaim", void 0);
__decorate([
    claim.Action('saveClaimWithPayload')
], Questions.prototype, "saveClaimWithPayload", void 0);
__decorate([
    pages.State('declarationComponentStack')
], Questions.prototype, "declarationComponentStack", void 0);
__decorate([
    pages.Getter('shouldCertify')
], Questions.prototype, "shouldCertify", void 0);
__decorate([
    pages.Getter('isUnavailable')
], Questions.prototype, "isUnavailable", void 0);
__decorate([
    pages.State('activeStep')
], Questions.prototype, "activeStep", void 0);
__decorate([
    pages.Action('selectActiveStep')
], Questions.prototype, "selectActiveStep", void 0);
__decorate([
    pages.Getter('activeQuestion')
], Questions.prototype, "activeQuestion", void 0);
__decorate([
    pages.Getter('activeSection')
], Questions.prototype, "activeSection", void 0);
__decorate([
    roomDamage.State('damages')
], Questions.prototype, "roomDamages", void 0);
__decorate([
    electricalDamage.State('damages')
], Questions.prototype, "electricalDamages", void 0);
__decorate([
    personalProperty.State('damages')
], Questions.prototype, "personalProperties", void 0);
__decorate([
    externalDamage.State('damages')
], Questions.prototype, "externalDamages", void 0);
__decorate([
    breakInDamage.State('damages')
], Questions.prototype, "breakInDamages", void 0);
__decorate([
    glassDamage.State('damages')
], Questions.prototype, "glassDamages", void 0);
__decorate([
    equipment.State('damages')
], Questions.prototype, "equipments", void 0);
__decorate([
    qualification.State('qualification')
], Questions.prototype, "robberyDamageQualification", void 0);
Questions = __decorate([
    Component({
        computed: {
            InsurerFeatureFlag() {
                return InsurerFeatureFlag;
            },
        },
        methods: { insurerHasFeature },
        components: {
            ClaimTypeQuestion,
            ProgressBar,
            SplashScreen,
            LargeSplashScreen,
            ClaimsButton,
            ...QuestionComponents,
        },
        scrollToTop: false,
        meta: {
            color: KEEP_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
        },
    })
], Questions);
export default Questions;
